<template>
  <section id="event-details">
    <!-- Non-destructive Loader -->
    <loader v-if="loading" :loading-message="loadingMessage" />

    <div class="content flex">
      <!-- Event image -->
      <div class="image--centered">
        <image-loader :image-url="event.eventImage" :alt="event.name" />
      </div>

      <hr class="divider divider--vertical" />

      <div class="details">
        <!-- Name, Category, date/time -->
        <event-subheader :event="event" />

        <!-- Action buttons -->
        <event-action-buttons
          :event="event"
          @event-error="onViewError"
          @attempting-registration="onAttemptRegister"
          @attempting-start="onStartAttempt"
          @attempting-join="onJoinAttempt"
          @cancel-registration="unregister"
          @registration-event="notifyRegistration"
        />

        <hr class="divider divider--tiny" />

        <!-- Instructors -->
        <h5 class="h6">Instructor</h5>
        <teacher-info :instructor="event.assignedTeacher" />

        <teacher-info
          v-if="isTeacherUser || isAdminUser"
          is-substitute
          :instructor="event.backupTeacher"
        />

        <!-- Notification/Error message -->
        <blockquote
          :key="error + eventNotification"
          :class="`slide-in-right ${error ? 'error' : 'success'}--text`"
          v-if="error || eventNotification"
        >
          <i :class="`fas fa-${error ? 'times' : 'check'}-circle`"></i>
          <b>&nbsp;{{ error || eventNotification }}</b>
        </blockquote>

        <hr class="divider divider--tiny" />

        <!-- Description (html) -->
        <div class="description" v-if="renderHTML" v-html="event.description" />
        <!-- Description (plaintext) -->
        <div class="description" v-else>
          <p v-for="(paragraph, i) in paragraphs" :key="i">{{ paragraph }}</p>
        </div>
      </div>
    </div>

    <!-- Admin Class/Event Details -->
    <event-details-admin v-if="isAdminUser" :event="event" />

    <!-- Product (Buy/Subscribe) Modal -->
    <modal-component
      :title="modalTitle"
      :confirmation-text="modalConfirmationText"
      :visible="showModal"
      @close="toggleModal"
      @confirm="confirmAndDismissModal"
    >
      <product-form
        v-if="showProductModal"
        :type="type"
        @payment-complete="register"
      />

      <template v-else>
        <hr class="divider grey-light" />

        <p>
          You are about to {{ showJoinZoomModal ? "join" : "start" }}
          <b>{{ event.name }}</b> via (an external) <b>Zoom</b> application.
          <span v-if="activeUser">
            <template v-if="showJoinZoomModal">
              To ensure that the instructor recognizes and admits you, please
            </template>
            <template v-if="showStartZoomModal">
              Please ensure that you
            </template>
            <b>
              sign into zoom with your
              <span class="accent--text">{{ appOrgName }}</span> email address
            </b>
            (
            <b class="grey--text">{{ activeUser.email }}</b>
            ).
          </span>
        </p>

        <hr class="divider divider-" />

        <p>
          <b class="success--text">Ready to continue?</b>
          This will temporarily take you out of the
          <b class="accent--text">{{ appOrgName }}</b> application.
        </p>
      </template>
    </modal-component>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import state from "../state";
import RegistrationsMixin from "./mixins/registrations.mixin";
import EventActionButtons from "./EventActionButtons.vue";
import EventDetailsAdmin from "./EventDetailsAdmin.vue";
import EventSubheader from "./EventSubheader.vue";
import Loader from "./Loader.vue";
import ModalComponent from "./ModalComponent.vue";
import ProductForm from "./ProductForm.vue";
import TeacherInfo from "./TeacherInfo.vue";
import ImageLoader from "./ImageLoader.vue";

export default {
  name: "EventDetails",

  components: {
    EventActionButtons,
    EventDetailsAdmin,
    EventSubheader,
    Loader,
    ModalComponent,
    ProductForm,
    TeacherInfo,
    ImageLoader
  },

  mixins: [RegistrationsMixin],

  props: { event: Object },

  data: () => ({
    eventError: null,
    loading: false,
    permissions: [],
    showJoinZoomModal: false,
    showProductModal: false,
    showStartZoomModal: false
  }),

  computed: {
    modalConfirmationText() {
      switch (true) {
        case this.showJoinZoomModal:
          return `Join ${this.type}`;

        case this.showStartZoomModal:
          return `Start ${this.type}`;

        default:
          return null;
      }
    },

    modalTitle() {
      const event = this.data;

      switch (true) {
        case this.showJoinZoomModal:
          return `Join ${event.name}`;

        case this.showStartZoomModal:
          return `Start ${event.name}`;

        default:
          return this.isOnlineClass
            ? "Join our Family!"
            : `Register for "${event.name}"`;
      }
    },

    paragraphs() {
      const p = this.event.description.split("\n");
      return p.filter(Boolean);
    },

    renderHTML() {
      if (!this.event) return false;
      const htmlMatch = new RegExp(`<("[^"]*"|'[^']*'|[^'">])*>`);
      return htmlMatch.test(this.event.description);
    },

    showModal() {
      const { showJoinZoomModal, showProductModal, showStartZoomModal } = this;
      return showJoinZoomModal || showProductModal || showStartZoomModal;
    }
  },

  async mounted() {
    document.title = `${this.type} • ${this.event.name} • ${APP_ORG} Admin`;
  },

  methods: {
    /**
     * React to global application state update
     */
    onAppState({ userRegistrations = {}, showProductModal }) {
      this.onUserRegistrations(userRegistrations);
      this.showProductModal = showProductModal;
    },

    /**
     * React to user "confirmation" action on active/visible modal
     */
    confirmAndDismissModal() {
      this.toggleModal();

      switch (true) {
        case this.assigned:
          return window.open(this.data.zoomStartLink);

        case Boolean(this.activeUser):
          return window.open(this.data.zoomLink);

        default:
          return null;
      }
    },

    /**
     * @override
     * React to a user's attempt to start an online event/class. Show modal with
     * prompts/instructions to start meeting via external zoom link/application
     */
    onStartAttempt() {
      this.showStartZoomModal = true;
    },

    /**
     * @override
     * React to a user's attempt to join an online event/class. Show modal with
     * prompts/instructions to join meeting via external zoom link/application
     */
    onJoinAttempt() {
      this.showJoinZoomModal = true;
    },

    /**
     * Close modal (clear all variables)
     */
    toggleModal() {
      this.showJoinZoomModal = false;
      this.showStartZoomModal = false;
      // hide global product modal if visible
      if (this.showProductModal) {
        const { showProductModal } = state.getState();
        state.showProductModal(!showProductModal);
      }
    }
  }
};
</script>

<style lang="scss">
@mixin padded() {
  padding-bottom: $md;
  padding-left: $md;
  padding-right: $md;
  padding-top: $md;
}

#event-details {
  .content {
    align-items: flex-start;
  }

  .details,
  .image--centered {
    @include padded();
  }

  .description p {
    margin-bottom: $xs;
  }

  .image--centered {
    flex-shrink: 0;
    padding-left: 0;
    padding-right: 0;
    width: 32rem;

    img {
      width: $parent;
    }
  }

  .list-item--instructor {
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  #event-details .image--centered {
    width: 24rem;
  }
}

@media screen and (max-width: 768px) {
  #event-details {
    .content {
      flex-direction: column;
    }

    .details,
    .image--centered {
      padding-bottom: $md;
      padding-top: $md;
      padding-left: 0;
      padding-right: 0;
      width: $parent;
    }
  }
}
</style>
