<template>
  <!-- Admin Class/Event Details -->
  <blockquote v-if="isAdminUser" id="event-details-admin">
    <h3 class="title text--centered">
      (Admin) <span class="accent--text">{{ type }}</span
      >Overview
    </h3>
    <hr class="divider divider--tiny" />

    <!-- Paid Event/Class highlights -->
    <div v-if="paidEventNotification" class="column">
      <h6 class="flex wide">
        <i class="fas fa-credit-card success--text"></i>
        <hr class="divider divider--vertical" />
        <b class="no-shrink">Paid Event:</b>
      </h6>

      <span class="primary--text wide" style="font-size: smaller">
        {{ paidEventNotification }}
      </span>
    </div>

    <hr class="divider divider--md grey-white" />

    <!-- Instructors -->
    <div class="form--row">
      <div class="column col-50">
        <h6 class="wide">Instructors</h6>

        <ul class="list column">
          <li
            class="list-item"
            v-for="(instructor, i) in instructors"
            :key="`${i}-${instructor.id}`"
          >
            <b class="success--text">{{
              i === 0 ? "Primary" : "Substitute"
            }}</b>
            <hr class="divider divider--vertical" />
            <span>{{ instructor.name }}</span>
          </li>
        </ul>
      </div>

      <hr class="divider divider--md grey-white" v-if="smallScreen" />

      <!-- Participants/Registrants -->
      <div class="column col-50">
        <h6 class="wide">Participants</h6>

        <ul class="list column">
          <li class="list-item">
            <b class="primary-light--text">Max:&nbsp;</b>
            <hr class="divider divider--vertical" />
            <span v-if="event.eventCap">{{ event.eventCap }}</span>
            <em v-else>No limit</em>
          </li>

          <li class="list-item">
            <b class="primary-light--text">Registered: </b>
            <hr class="divider divider--vertical" />
            <em v-if="registrantCount === 0">No attendees</em>
            <button
              v-else
              class="outline preview-registrants"
              @click.prevent="() => (showRegistrants = true)"
            >
              <i class="fas fa-eye"></i>
              <span>&nbsp;{{ registrantCount }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <!-- Schedule -->
    <div class="column">
      <span class="list-item">
        <b class="primary-text">Schedule:&nbsp;</b>

        <template v-if="sameAsInstructorTZ">
          <hr class="divider divider--vertical" />
          <span v-if="sameAsInstructorTZ">{{
            eventDate.toFormat("ff ZZZZ")
          }}</span>
        </template>

        <template v-else>
          <!-- Instructor, User times -->
          <ul class="list-item column">
            <li class="list-item">
              <b class="primary-light--text">Instructor:</b>
              <hr class="divider divider--vertical" />
              {{ eventDate.toFormat("ff ZZZZ") }}
            </li>

            <li class="list-item">
              <b class="primary-light--text">You:</b>
              <hr class="divider divider--vertical" />
              {{ eventDateLocal.toFormat("ff ZZZZ") }}
            </li>
          </ul>
        </template>
      </span>
    </div>

    <hr class="divider divider--md grey-white" v-if="smallScreen" />

    <!-- Zoom Link -->
    <p>
      <b v-if="!event.zoomMeetingId">
        <i class="fas fa-exclamation-triangle accent--text"></i>
        <b class="error--text">&nbsp;<em>No Zoom Link!</em></b>
      </b>
      <b class="success--text" v-else>
        <i class="fas fa-check-circle success--text"></i>
        <em>Zoom meeting linked</em>
      </b>
    </p>

    <!-- EventBrite Link -->
    <p>
      <b v-if="Boolean(event.eventLink)" class="success--text">
        <i class="fas fa-check-circle success--text" />
        <em>&nbsp;EventBrite linked</em>
      </b>
      <b v-else class="error--text">
        <i class="fas fa-exclamation-triangle accent--text" />
        <em v-if="!event.eventLink">&nbsp;No EventBrite link!</em>
      </b>
    </p>

    <!-- Registrants Modal -->
    <modal-component
      :title="`${type} Registrants`"
      :visible="showRegistrants"
      @close="() => (showRegistrants = false)"
    >
      <users-list-legend />

      <user-list-item v-for="user in registrants" :key="user.id" :user="user" />

      <em v-if="registrants.length === 0" class="list-item">
        No attendees found
      </em>
    </modal-component>
  </blockquote>
</template>

<script>
/* eslint-disable no-undef */
import {
  getClassRegistrations,
  getEventRegistrations
} from "../models/registrations";
import { getUserById } from "../models/user";
import { ONLINE_CLASS } from "../helpers/classes-events.helpers";
import EventsMixin from "./mixins/events.mixin";
import ModalComponent from "./ModalComponent.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import UserListItem from "./UserListItem.vue";
import UsersListLegend from "./UsersList.Legend.vue";

export default {
  name: "EventDetailsAdmin",

  components: { ModalComponent, UserListItem, UsersListLegend },

  mixins: [EventsMixin, PermissionsMixin],

  props: { event: Object },

  data: () => ({
    registrants: [],
    showRegistrants: false
  }),

  computed: {
    instructors() {
      const { assignedTeacher, backupTeacher } = this.event;
      return [assignedTeacher, backupTeacher];
    },

    sameAsInstructorTZ() {
      const evDate = this.eventDate.toFormat("ff ZZZZ");
      const evLocal = this.eventDateLocal.toFormat("ff ZZZZ");
      return evDate === evLocal;
    },

    smallScreen() {
      return window.innerWidth <= 600;
    },

    paidEventNotification() {
      const { productId, eventCategory } = this.event;
      switch (true) {
        case Boolean(productId):
          return "This is a paid event";

        case eventCategory === ONLINE_CLASS:
          return "Online classes require registration or a drop-in rate.";

        default:
          return null;
      }
    },

    registrantCount() {
      return this.registrants.length;
    }
  },

  async mounted() {
    // Check if user is registered for current event
    // Get all current registrations for event/class
    let registrations = [];

    if (this.type === "Event") {
      registrations = await getEventRegistrations({ eventId: this.event.id });
    } else {
      registrations = await getClassRegistrations({ classId: this.event.id });
    }
    const fetchRegistrant = r => getUserById(r.userId);
    const registrants = await Promise.all(registrations.map(fetchRegistrant));
    this.registrants = registrants;
  }
};
</script>

<style lang="scss">
@mixin padded() {
  padding: $sm;
}

#event-details-admin {
  @include padded();
  border-color: $accent;

  b:not([class]) {
    color: black;
  }

  p {
    line-height: $xlg;
    width: $parent;
  }

  .list-item {
    border: none;
    line-height: $lg;
    min-height: $lg;
  }

  .preview-registrants {
    box-shadow: none;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  #event-details-admin {
    .form--row {
      flex-direction: column;

      > * {
        width: $parent;
      }
    }
  }
}
</style>
